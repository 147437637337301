import React from 'react';

import Container from '@components/Container';
import FlexBox from '@components/layout/FlexBox';
import Wrapper from '@components/layout/Wrapper';
import Layout from '@mainComponents/Layout';
import settings from '@settings';

const Privacy = () => (
	<Layout
		pageTitle="Privacy"
		headerType='page-without-image'
		showHeader
		path="/privacy"
	>
		<Wrapper background={settings.colors.light}  >
			<Container>
				<FlexBox align='center' direction='column' hasFullWidth>
					<div css={settings.mq({maxWidth: ['100%', '100%', '400px']})}>
						<p>
							<span css={{fontWeight: 600}}>Hey</span>, we are currently not using any cookies and we are not collecting any personal data as well. This Site is currently hostet on the CDN of Netlify:
						</p>
						<p>Netlify<br />
							4 Montgomery St #300,<br />
							San Francisco 
						</p>

							
						<p>
							If you have any further question regarding your data privacy or anything else, feel free to <a className='is-link' href="mailto:nocogirls@nocogirls.com">contact us </a> .
						</p>
					</div>
				</FlexBox>
			</Container>
		</Wrapper>

		
	</Layout>
);

export default Privacy;